.telstra-footer {
  flex-shrink: 0;
  width: 100%;
  background-color: #F4F4F4;
  box-sizing: border-box;
  margin-top: 7.5rem;
}

.footer-container {
  display: flex;
  justify-content: center;
  padding: 20px 102px;
  background: #151515;
  color: #F8F8F8;
  font-family: "Montserrat Regular", Arial !important;
  font-size: 14px;
  height: 62px;
}

.footer-container nav a {
  color: #F8F8F8;
  font-family: "Montserrat Regular", Arial !important;
  font-size: 14px;
  text-decoration: underline !important;
}

.footer-first-three a:first-child {
  padding-left: 0;
}

.footer-container a {
  color: #F8F8F8;
  font-family: "Montserrat Regular", Arial;
  font-size: 14px;
  letter-spacing: 0.18px;
  line-height: 17px;
  text-decoration: none;
  display: inline-block;
  text-decoration: underline !important;
}

.footer-container li {
  padding-left: 40px;
}

.footer-last-two {
  list-style: none;
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 767px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    height: 180px;
    padding: 80px 27px;
  }
  .footer-first-three {
    display: flex;
    flex-direction: column;
  }
  .footer-last-two {
    display: flex;
    flex-direction: column;
  }
  .footer-container a {
    padding-left: 0;
  }
  .footer-container li {
    padding-left: 0px;
  }
  .footer-container-three a:first-child {
    padding-top: 0 !important;
  }
  nav {
    padding-bottom: 10px;
  }
}

