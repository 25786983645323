.Refund-Image{
    margin-bottom: 40px;
}
.Refund-Page-Title{
    margin-bottom: 40px;
}

.Refund-Page-Content{
    margin-bottom: 25px;
    width: 459px;
    font-size: 16px;
    font-family: 'Montserrat Regular', Arial;
}

.Refund-Page-TryAgain{
    margin-top: 27px;
}

.Refund-Page-NeedHelp{
    margin-top: 60px;
    font-size: 16px;
    letter-spacing: 0.25px;
    line-height: 20px;
    color: #414141;
}

.Refund-page-container {
    margin-top: 95px;
}

.RefundAblebtnCls{
    outline-color: #FF4300 !important;
    .able-icon use, .able-icon path {
        fill: #FF4300 !important;
    }
}
.RefundChatSvg{
    width: 300px !important;
}
.Refund-Page-Title .Page-title {
    height: auto !important;
    margin-bottom: 23px;
}
@media (max-width: 767px) {
    .Refund-Page-Content {
        width: auto;
    }
    .Refund-page-container {
        margin-top: 0;
    }
}
