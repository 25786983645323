/* Uses Bootstrap stylesheets for styling, see linked CSS*/
/* body {
    background-color: #fff;
    padding: 15px;
  } */
@use "../../../node_modules/@able/web/src/foundations/spacing" as spacing;
  .toast {
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: 9999;
  }
  
  .bootstrap-basic {
    background: white;
  }
  
  /* Braintree Hosted Fields styling classes*/
  .braintree-hosted-fields-focused {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .braintree-hosted-fields-focused.is-invalid {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

  .mt-checkbox__text {
    pointer-events: none;
}

.mt-checkbox__checkmark {
    pointer-events: none;
}

.mt-checkbox__checkmark svg {
  width: 28px;
  height: 28px;
}

  .savedCreditCardCheckboxText {
    font-size: 14px !important;
    font-weight: 600;
}

.AdhocCcAutoRechargeCheckboxText {
  font-size: 14px !important;
  font-weight: 600;
}
.AdhocCcAutoRechargeCheckbox {
  margin-top: 10px;
}
.AdhocCcAutoRechargeCheckboxErrMsg{
  font-size: 14px !important;
  font-weight: 600;
  color: red;
}

.CreditCardPayment-expiry label {
  display: block;
}

.cvvErrorMsg{
  display: flex;
  font-size: 14px !important;
}

@media only screen and (min-width: 1024px) {
  .mt-checkbox__text {
    margin-top: 0px !important;
}
}

.CreditCardPayment-label {
  margin-bottom: 0px !important;
}

.CreditCardPayment-hosted-field {
  height: 45px;
  display: block;
  background-color: transparent;
  color: rgba(0, 0, 0, .87);
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, .87);
  outline: 0;
  width: 100%;
  font-size: 16px;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  position: relative;
}

.CreditCardPayment-hosted-field.customselect {
  background: url('./chevron.svg') no-repeat right;
  background-position-x: 98%;
}

.CreditCardPayment-hosted-field.is-invalid {
  border-bottom: 1px solid red;
  transition: all 200ms ease;
}

.CreditCardPayment-invalid-message {
  visibility: hidden;
}

.CreditCardPayment-invalid-message.is-invalid {
  visibility: visible;
  color: red;
}

.braintree-hosted-fields-focused {
  border-bottom: 2px solid #FF4300;
  transition: all 200ms ease;
}

.braintree-hosted-fields-focused.is-invalid {
  border-bottom: 2px solid red;
  box-shadow: none;
  transition: all 200ms ease;
  outline: 0;
}

.braintree-hosted-fields-invalid {
  transition: all 200ms ease;
}

#CcPayment-logo-container {
  position: absolute;
  display: flex;
  top: 0em;
  right: 0em;
}

#CcPayment-logo-container div:not(:first-child){
  margin-left: 0.5rem;
}

.CcPayment-inactive-logo {
  opacity: 0.2;
}

.CreditCardPayment-expiry-cardinfo {
  position: relative;
}

.CreditCardPayment-expiry {
  width: 100%;
}

.CreditCardPayment-submit-button {
  width: 100%;
  padding: 20px;
  font-size: 16px;
  margin-left: 0px;
}

@media only screen and (max-width: 767px) {
  .CreditCardPayment-submit-button {
    width: 100% !important;
    margin-left: 0px;
  }
}

.FinePrintError{
  color: #C91800;
  display: flex;
  font-family: "Montserrat Regular", Arial !important;
}

.FinePrint.is-invalid {
  color: #C91800;
  display: flex;
}

.FinePrint.is-valid {
  display: flex;
}

.FinePrintTextAlign{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.FinePrintTextAlign svg {
  min-width: 20px;
}

.CCFinePrintError {
  color: #C91800 !important;
  font-family: "Montserrat Regular", Arial !important;
  font-size: 14px;
  margin-top: 2px !important;
}

.CCFinePrintError svg {
  min-width: 20px;
}

#braintree-cc-number-errors {
  position: absolute;
}

.CreditCardPayment-error-container {
  display: flex;
  font-size: 14px !important;
}