.ChooseAmount--grid-container {
  width: 100%;
}
@media screen and (max-width: 47.99609375rem) {
  .ChooseAmount--grid-container {
    margin-top: 0;
  }
}
.ChooseAmount--grid-container__wrapper {
  width: 100%;
}
@media screen and (min-width: 48rem) {
  .ChooseAmount--grid-container__wrapper {
    margin-left: 8.33%;
    width: 83.33%;
  }
}
@media screen and (max-width: 47.99609375rem) {
  .ChooseAmount--grid-container__wrapper {
    margin-left: 0;
    padding-left: 0;
  }
}
.ChooseAmount--grid-container__something-else {
  width: 100%;
}
@media screen and (min-width: 48rem) {
  .ChooseAmount--grid-container__something-else {
    margin-left: 8.33%;
    width: 33.33%;
  }
}
@media screen and (max-width: 47.99609375rem) {
  .ChooseAmount--grid-container__something-else {
    margin-left: 0;
    padding-left: 0;
  }
}
.ChooseAmount--grid-container__card:nth-of-type(odd) {
  width: 100%;
  padding-bottom: 24px;
}
@media screen and (min-width: 48rem) {
  .ChooseAmount--grid-container__card:nth-of-type(odd) {
    margin-left: 0%;
    width: 50%;
  }
}
.ChooseAmount--grid-container__card:nth-of-type(even) {
  width: 100%;
  padding-bottom: 24px;
}
@media screen and (min-width: 48rem) {
  .ChooseAmount--grid-container__card:nth-of-type(even) {
    margin-left: 0%;
    width: 50%;
  }
}
.ChooseAmount--grid-container__card_promotion_details {
  position: absolute !important;
  top: -15px;
  left: 19px;
  font-family: "Montserrat Regular", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 14px !important;
  font-weight: bold !important;
  letter-spacing: -0.02px !important;
  line-height: 18px !important;
  text-align: center;
}
@media screen and (min-width: 105rem) {
  .ChooseAmount--grid-container__card_promotion_details {
    top: -18px;
  }
}
.ChooseAmount--grid-container__initial_container {
  width: 100%;
}
@media screen and (min-width: 48rem) {
  .ChooseAmount--grid-container__initial_container {
    margin-left: 0%;
    width: 37.5%;
  }
}
.ChooseAmount--grid-container__initial_container .tabs-container {
  margin-top: 32px;
  margin-bottom: 25px;
}
.ChooseAmount--grid-container__heading {
  width: 100%;
}
@media screen and (min-width: 48rem) {
  .ChooseAmount--grid-container__heading {
    margin-left: 0%;
    width: 83.33%;
  }
}
.ChooseAmount--grid-container__heading_content {
  width: 100%;
  background-color: #F4F4F4;
  padding: 15px 0 15.5px 15px;
  margin-bottom: 32px;
  margin-top: 8px;
}
@media screen and (min-width: 48rem) {
  .ChooseAmount--grid-container__heading_content {
    margin-left: 0%;
    width: 100%;
  }
}
.ChooseAmount--grid-container__image_container {
  width: 0%;
}
@media screen and (min-width: 48rem) {
  .ChooseAmount--grid-container__image_container {
    margin-left: 0%;
    width: 41.66%;
  }
}
.ChooseAmount--grid-container__denomination_heading {
  margin-left: 0%;
  width: 75%;
  color: #282828;
  font-family: "Montserrat Regular";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  padding-top: 26px;
  margin-left: 20px;
}
.ChooseAmount--grid-container__denomination_price {
  margin-left: 0%;
  width: 25%;
  width: auto;
  color: #282828;
  font-family: "Montserrat Regular";
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 41.6px;
  padding-top: 16px;
  flex: 1;
}

.button-color {
  color: #db3a00;
}
.button-color svg:not(.able-icon):not(.able-picto) path {
  fill: currentColor;
}

.ChooseAmount__border_margin {
  position: relative;
  border: 1px solid #BDBDBD;
  background-color: white;
  border-radius: 3px !important;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
}
.ChooseAmount__border_margin_promotion {
  border-top: 5px solid #FF4300;
}
.ChooseAmount__price-value {
  line-height: 41.6px;
  padding-top: 16px;
}
.ChooseAmount__price-value-with-headline {
  margin-right: 20px;
  text-align: right;
}
.ChooseAmount__price-value-without-headline {
  text-align: center;
}
.ChooseAmount__heading-value {
  line-height: 28px;
  padding-top: 21px;
  margin-left: 20px;
}
.ChooseAmount__div-summary {
  line-height: 24px;
  padding-top: 20px;
  float: left;
  margin-left: 20px;
  margin-right: 15px;
}
.ChooseAmount__div-summary p {
  color: #282828;
}
.ChooseAmount__div_product {
  line-height: 21px;
  display: inline;
}
.ChooseAmount__div_product p {
  color: #757575;
  font-size: 14px;
  letter-spacing: 0.18px;
  line-height: 21px;
  margin-right: 15px;
}
.ChooseAmount__div-fineprint {
  margin-top: 8px;
  margin-bottom: 15px;
}
.ChooseAmount__div-value, .ChooseAmount__div-hasValue-fineprint {
  margin-left: 20px;
  margin-top: 8px;
}
.ChooseAmount__div-empty-fineprint {
  height: 21px;
}
.ChooseAmount__div-able-btn {
  width: 100% !important;
  padding-top: 20px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.ChooseAmount__able-action-btn {
  width: 100% !important;
  color: black !important;
}
.ChooseAmount__div-whats-included .mt-showhide__button {
  color: #414141 !important;
  font-family: "Montserrat Regular";
  font-size: 14px !important;
  letter-spacing: 0.18px;
  font-weight: normal !important;
  margin-left: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  width: 94%;
}
.ChooseAmount__div-whats-included .mt-showhide__button__icon {
  top: -8px !important;
}
.ChooseAmount__div-whats-included .mt-showhide {
  margin-left: 0;
}
.ChooseAmount__div-whats-included .mt-showhide__content__inner {
  margin-top: 0 !important;
  padding-bottom: 30px !important;
  background-color: #FFF3EB;
  border-top: 1px solid #BDBDBD;
}
.ChooseAmount__div-denomination-details {
  padding: 5% 5% 0 5%;
}
.Chooseamount-ShowHide-Button-Custom .ChooseAmount__div-denomination-details {
  padding: 5% 5% 0 0%;
}
.ChooseAmount__div-denomination-content {
  line-height: 21px;
  display: flex;
  flex-direction: row;
}
.ChooseAmount__div-denomination-content p {
  color: #414141;
}
.ChooseAmount__denomination-heading {
  font-size: 14px;
  font-weight: bold;
  padding-left: 5px;
  padding-bottom: 5px;
}
.ChooseAmount__denomination-content {
  padding-left: 5px;
}
.ChooseAmount__svg-tick path {
  fill: #FF4300 !important;
}
.ChooseAmount__card_margin {
  margin-top: 41px;
}
.ChooseAmount__button-color {
  color: #FF4300;
}
.ChooseAmount__button-color svg:not(.able-icon):not(.able-picto) path {
  fill: currentColor;
}
.ChooseAmount__button-color:hover {
  color: #b32f00;
}
.ChooseAmount__button-color:hover svg:not(.able-icon):not(.able-picto) path {
  fill: currentColor;
}

.Category__empty-container {
  margin-top: 32px;
}

.SubCategory__palette {
  width: 100%;
}
@media screen and (min-width: 48rem) {
  .SubCategory__palette {
    margin-left: 0%;
    width: 100%;
  }
}
.SubCategory__palette ul {
  display: flex;
  flex-flow: wrap;
  padding-left: 0;
  list-style-type: none;
}
.SubCategory__btn-style {
  border: 1px solid #FF4300;
  border-radius: 24px;
  height: 30px;
  text-align: center;
  padding: 10px 15px 4px 15px;
  position: relative;
  margin-right: 12px;
  cursor: pointer;
  min-width: 44px;
  min-height: 44px;
}
.SubCategory__btn-style p {
  color: #000000;
}
.SubCategory__input-style {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}
.SubCategory__btn-style:hover, .SubCategory__btn-selected {
  background-color: #FF4300;
}
.SubCategory__btn-style:hover p,
.SubCategory__btn-style:hover p:hover, .SubCategory__btn-selected p,
.SubCategory__btn-selected p:hover {
  color: #000000;
}

@media screen and (max-width: 47.99609375rem) {
  #ChooseAmount-title-container {
    margin-top: 0px;
  }
}

.ChooseAmount__price-value-with-headline {
  margin-right: 20px;
  text-align: right;
  float: right;
}

.ChooseAmount-Lozenge {
  background-color: #FF4300 !important;
  border-color: #FF4300 !important;
}

.Voucher-chooseamount-info {
  margin-top: 24px;
  margin-bottom: 40px;
}

.voucher-info-text {
  height: 44px;
  width: 458px;
  color: #3B3B3B;
  font-family: "Telstra Akkurat";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}
@media screen and (max-width: 47.99609375rem) {
  .voucher-info-text {
    width: auto;
  }
}

.pricevalue-beforediscount {
  color: #757575;
  font-family: "Montserrat Regular", Arial !important;
  font-size: 20px;
  letter-spacing: 0.15px;
  line-height: 28px;
  font-style: normal;
  font-weight: normal;
}

.mt-showhide__content p a.insternational-calling {
  background-color: transparent;
}

.country-name {
  line-height: 35px;
}

.country__close__button {
  width: 100%;
}

.able-ModalContent--Comfortable footer {
  background-color: transparent;
}

body {
  overflow-x: hidden !important;
}

.showhide__content_remove_accordian {
  margin-left: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.showhide__content_remove_accordian p a {
  transition: color 250ms, border 250ms, box-shadow 250ms, text-shadow 250ms, background-color 250ms;
  text-decoration: none;
  color: #0064d2;
  background-color: #fff;
  font-weight: 400;
  border-bottom: 0.0625rem solid;
}

.zone-data-ModalContent.able-ModalContent--Comfortable.able-Modal_isScrolling header {
  min-height: 45px;
}

p.country-name {
  font-weight: 400;
}

.Chooseamount-ShowHide-Button-Custom button {
  color: #414141 !important;
  font-family: "Montserrat Regular";
  font-size: 14px !important;
  letter-spacing: 0.18px;
  font-weight: normal !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  width: 94%;
  box-shadow: none !important;
}

.Chooseamount-ShowHide-Button-Custom .accordion--expanded > div {
  margin-top: 0 !important;
  padding-bottom: 30px !important;
  background-color: #FFF3EB;
  border-top: 1px solid #BDBDBD;
}

.Chooseamount-ShowHide-Button-Custom.able-Accordion--compact > div.accordion--expanded > div {
  box-shadow: none;
}

.able-Accordion--compact > div > h2 button:hover {
  box-shadow: none;
  background-color: transparent;
}

