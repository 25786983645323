.spinner {
   background: url(./button-loader.svg) no-repeat 50% 50%;
   content: '';
   background-size: 24px 24px !important;
   background-position: center !important;
   padding: 12px !important;
}

.spinner {
   animation-name: spin;
   animation-duration: 1000ms;
   animation-iteration-count: infinite;
   animation-timing-function: linear;
   -webkit-animation-name: spin;
   -webkit-animation-duration: 1000ms;
   -webkit-animation-iteration-count: infinite;
   -webkit-animation-timing-function: linear;
   -moz-animation-name: spin;
   -moz-animation-duration: 1000ms;
   -moz-animation-iteration-count: infinite;
   -moz-animation-timing-function: linear;
   -ms-animation-name: spin;
   -ms-animation-duration: 1000ms;
   -ms-animation-iteration-count: infinite;
   -ms-animation-timing-function: linear;
 }
 
 @-ms-keyframes spin {
   from {
     -ms-transform: rotate(0deg);
   }
   to {
     -ms-transform: rotate(360deg);
   }
 }
 
 @-moz-keyframes spin {
   from {
     -moz-transform: rotate(0deg);
   }
   to {
     -moz-transform: rotate(360deg);
   }
 }
 
 @-webkit-keyframes spin {
   from {
     -webkit-transform: rotate(0deg);
   }
   to {
     -webkit-transform: rotate(360deg);
   }
 }
 
 @keyframes spin {
   from {
     transform: rotate(0deg);
   }
   to {
     transform: rotate(360deg);
   }
 }