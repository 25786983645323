.info-banner{
  max-width: 100% !important;
  a {
    color: #FF4300 !important;
  }
  svg:not(.able-icon):not(.able-picto) {
    path, rect{
      fill: currentColor !important;
    }
  }
}
