.EmailReceipt {
    display: flex;
}

.spinner-visible {
    background: #00aaf3 url(ajax-loader-big.gif) no-repeat 50% 50% !important;
}
.invisible {
    visibility: hidden;
}

.EmailReceipt-container {
    display: flex;
    align-items: left;
    flex-direction: column;
}

.EmailReceipt-content {
    display: flex;
    flex-direction: column;
}

#email-address {
    width: 100% !important;
}

.EmailReceipt-next {
    align-self: left;
    padding-right: 20px !important;
    width: 100px;
}

.EmailReceipt-header {
    display: flex;
    align-items: left;
    justify-content: left;
    color: #333333;
    font-weight: bold;
    line-height: 20px;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 5px;
}
.EmailReceipt-green-tick {
    color: green !important;
    padding-left: 10px;
    padding-right: 10px;
}
.EmailReceipt-footer {
    font-size: 12px;
    font-style: italic;
    margin-top: 10px;
}

.EmailReceipt-success-footer {
    color: #1964C8;
    line-height: 20px;
    margin-left: fill;
    margin-bottom: 20px;
}

.EmailReceipt-success-content {
    display: flex;
    justify-content: center;
}

#sendEmailLink{
    text-decoration: underline!important;
    font-size: 16px;
}

.EmailReceipt-flex-content {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
    justify-content: left;
}

.receipt-error-title {
    font-size: 18px;
    margin-bottom: 15px;
}
.error-icon-settings {
    padding: 0 0 0 20px;
    line-height: 22px;
}

@media (max-width: 767px) {

    .EmailReceipt-flex-content  {
        justify-content: left;
    }

    .EmailReceipt-footer {
        padding-bottom: 20px;
    }

    .EmailReceipt-next {
        padding-top: 15px;
        width: 100%;
        margin-bottom: 0 !important;
    }

    .EmailReceipt-content {
        align-items: stretch;
    }

    .EmailReceipt-success-content {
        width: 100%;
    }

    .EmailReceipt-form-container{
        width: 100%;
    }

    .EmailReceipt-toast {
        width: 90% !important;
    }
}

.EmailReceiptErrorMessage {
    font-size: 14px !important;
    line-height: 20px !important;
}

.EmailReceipt-header h1, .EmailReceipt-label {
    font-size: 16px;
    color: #141414;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    font-family: "Montserrat Bold", Arial !important;
}
@media (min-width: 767px) {
.EmailReceipt-toast {
    width: 982px !important;
}
}
