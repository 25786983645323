.AutoRecharge {
    &-container {
        display: flex;
        flex-direction: row;
    }

    &-incentive-container,
    &-icon-container  {
        display: flex;
        flex-direction: row;
    }

    &-lozenge {
        background-color: #FF4300 !important;
        border-color: #FF4300 !important;
    }

}

.AutoRecharge-Inactive.able-icon use, .AutoRecharge-Inactive.able-icon path {
    fill: #FF4300 !important;
}

.AutoRecharge-Active.able-icon use, .AutoRecharge-Active.able-icon path {
    fill: #FF4300 !important;
}