@use '../../node_modules/@able/web/src/vars' as vars;
@use "../../node_modules/@able/web/src/foundations/grid" as grid;
@use "../../node_modules/@able/web/src/foundations/spacing" as spacing;
@use "../../node_modules/@able/web/src/mixins" as mixins;

@mixin responsive-bg-image($image-width, $image-height) {
  background-size: 100%;
  height: 0;
  padding-bottom: percentage($image-height / $image-width);
  display: block;
}

.AppDownload {
    @include grid.breakpointColumns(12);
    margin-top: spacing.spacing(5);

    &__container {
      @include grid.breakpointColumns(12);
      margin-bottom: spacing.spacing(.5);
      box-sizing: border-box;
      border: 1px solid #D2D2D2;
      border-radius: 5px;
      padding-left: 0;
      padding-right: 0;
      margin-right: 10%;
    }

    &__logocontainer {
      @include grid.breakpointColumns(12);
      margin-bottom: spacing.spacing(3);
      background: url('./boost-downloadapp.jpg') no-repeat;
      @include responsive-bg-image(204, 110);
    }

    &__titlecontainer {
      margin-bottom: spacing.spacing(2);
      display: flex;
      flex-direction: row;

      &--title {
        margin-bottom: spacing.spacing(.5);
        float: left;
        width: 50%;
        flex: 1;
        padding-left: 8%;
      }

      &--image {
        background: url('./mytelstra-icon.svg') no-repeat 100% 50%;
        margin-bottom: spacing.spacing(.5);
        flex: 1;
        float: right;
        margin-right: 8%;

        @include mixins.mq-alias("viewportSM") {
          min-height: 64px;
        }
        @include mixins.mq-alias("viewportXS") {
          min-height: 64px;
        }
      }
    }
    &__contentcontainer {
      @include grid.breakpointColumns((offset: 1, cols: 10));
      margin-bottom: spacing.spacing(4);

      .summary-item {
        margin-top: 16px;
      }

      .summary-label-content {
        color: #414141 !important;
        font-size: 16px !important;
        font-weight: 300 !important;
        letter-spacing: 0.15px !important;
        line-height: 24px !important;
        margin-bottom: 5px !important;
      }

      .summary-item-content {
          color: #414141 !important;
          font-size: 16px !important;
          font-weight: 300 !important;
          letter-spacing: 0.15px !important;
          line-height: 24px !important;
          margin-bottom: 5px !important;
          padding-left: 16px;
      }

      .summary-item path {
        fill: #FF4300;
      }
  
    }
  
    &__button {
      width: 100% !important;
      border-color: black;
      color: black !important;
    }
  
    &__tick {
      float: left;
      padding-right: 16px;
      path {
        fill: #FF4300;
      }
    }
  }
