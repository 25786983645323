.ViewBalance {
  padding-top: 24px;
}
.ViewBalance__icon {
  background: url("./view-balance-icon.svg") no-repeat;
  min-height: 56px;
}
.ViewBalance__title {
  margin-top: 16px;
  margin-bottom: 12px;
}

