@use "../../node_modules/@able/web/src/vars" as vars;
@use "../../node_modules/@able/web/src/foundations/grid" as grid;
@use "../../node_modules/@able/web/src/foundations/spacing" as spacing;
@use "../../node_modules/@able/web/src/mixins" as mixins;

.SuccessPage--grid {
    &-container {
        @include grid.breakpointColumns(12);

        &__maincontent {
            @include grid.breakpointColumns(12, (offset: 1, cols: 4));
            margin-bottom: spacing.spacing(.5);

            @include mixins.mq-alias("viewportSM") {
                order: 1;
            }
            @include mixins.mq-alias("viewportXS") {
                order: 1;
            }

        }

        &__appdownload {
            @include grid.breakpointColumns(12, (offset:2, cols: 4));
            margin-bottom: spacing.spacing(.5);

            @include mixins.mq-alias("viewportSM") {
                order: 2;
            }
            @include mixins.mq-alias("viewportXS") {
                order: 2;
            }
        }
    }
}

.SuccessPage {
    display: flex;
    flex-direction: column;
    margin-top: spacing.spacing(5);
    @include mixins.mq-alias("viewportXS") {
        margin-top: spacing.spacing(4);
    }
}

.SuccessPage-successful-banner {
    color: #333333;
    font-size: 32px;
    line-height: 28px;
    text-align: left;
    font-family: "Montserrat Regular", Arial;
    font-weight: 300;
}

.SuccessPage-successful-tick {
    background-image: url('./green-tick.svg');
    background-repeat: no-repeat;
    background-position: center;
    height: 60px;
    margin-bottom: 30px;
}

.success-wrapper {
    margin: 0px 0px 15px 0px;
}

.successrow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.successcolumn1 {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    flex-grow: 1;
    width: 100%;
}

.successcolumn1 hr {
    width: 100%;
    margin: 0;
}

.my-telstra-app-label {
    font-size: 16px;
    font-weight: 600;
}
.successcolumn2 {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 0;
    margin-top: 8px;
    flex-grow: 0;
}

.successdesk-image {
    background: url('./ppr-illustration-desktop-receipt.svg');
    width: 400px;
    height: 400px;
}

.SuccessPage-mobileImageDiv {
    visibility: hidden;
    height: 0px;
}

.successmob-image {
    background: url('./ppr-illustration-mobile-receipt.svg');
    width: 120px;
    height: 120px;
}

.SuccessPage-info-div {
    border-left: 5px solid #00AAF3;
    margin: 44px 40px 0px 0px;
}
.SuccessPage-info-heading {
    margin-left: 26px;
    font-weight: bold;
    line-height: 20px;
}
.SuccessPage-info-content {
    margin: 16px 0px 0px 45px;
    line-height: 20px;
}

.SuccessPage-NextStep {
    margin-top: 32px;
    font-size: 26px;
    font-weight: 600;
}

.InProgressPage-info-content {
    line-height: 20px;
    margin-top: 32px;
    margin-bottom: 30px;
    font-size: 16px;
}
.SuccessPage-info-content-gap {
    margin-top: 32px;
}
.SuccessPage-info-content-gap-Inprogress {
    margin-top: 17px;
}
@media (max-width: 767px) {

    .SuccessPage-successful-banner {
        line-height: 45px;
    }

    .SuccessPage-mobileImageDiv {
        visibility: visible;
        height: 120px;
    }
    .success-wrapper {
        margin: 0px;
    }
    .successrow {
        display: inline-block;
        margin-left: 0px;
    }
    .successcolumn2 {
        visibility: hidden;
        height: 0px;
    }

    .checkBalBtn{
        width: 100% !important;
        margin-bottom: 0px !important;
    }
    .SuccessPage-info-div {
        width: 100%;
    }
}

.DownloadReceipt-container{
    margin-top: 24px;
}

#hideInclusions{
    display:none;
}

#hideAmount{
    display:none;
}

/* PDF Download css*/
.download-or {
    color: #333333;
}

.download-text {
    width: 100%;
    color: #1964C8;
    line-height: 20px;
    text-align: left;
}

.return-url {
    text-align: left;
    padding-top: 16px;
}
.checkBaltxt{
    height: 20px;
    font-size: 14px;
    line-height: 20px;
}

.checkBalBtn{
    width: auto;
}

.success-page-icons {
    height: 26px;
    width: 32px;
    color: #1964C8 !important;
    margin-right: 9px;
    font-size: 28px;
    line-height: 26px;
    text-align: center;
}

a:hover {
  text-decoration: none !important;  
}

a {
  cursor: pointer;
  text-decoration: none !important; 
}
