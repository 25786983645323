.mt-global-header {
    position: absolute;
    z-index: 99;
    background: #151515;
    border-bottom: 2px solid #FF4300;
    &__content-push {
        height: 4rem;
        @media (max-width: 1023px) {
            height: 3rem;
        }
    }
    &:before{
        display: none;
    }
    @media (max-width: 1023px) {
        height: 3rem !important;
    }
    &__nav__button.mt-button {
        display: flex !important;
        width: 89px;
        margin-top: 1.1rem !important;
        @media (max-width: 1023px) {
            margin-right: 0 !important;
            margin-top: 0.7rem !important;
        }
        .mt-button{
            &__icon{
                display: flex;
                transform: none !important;
                transition: none !important;
                right: 4rem;
                top: 0;
                .able-icon {
                    margin-right: 0.1rem;
                    margin-left: 0rem;
                }
            }
            &__content{
                color: #FF4300;
                padding: 0rem 0rem 0.1rem 1.5rem !important;
                line-height: 1.5rem !important;
                &:hover{
                    color: #db3a00 !important;
                    cursor: pointer;
                }
                &:hover {
                    color: #b71600;
                    + .mt-button__icon use {
                        fill: #b71600;
                    }
                }
            }
        }
    }
    &__secondary-nav-without-children {
        margin-right: 5.5%;
        @media (max-width: 1023px) {
            margin-right: 12px;
        }
        button {
            height: 2.8rem !important;
            padding: 0 !important;
            &:hover {
                background-color: transparent !important;
                cursor: auto;
            }
            &:focus {
                border: 1px solid #0064D2;
                box-shadow: 0px 0px 0px 1px #0099F8;
                background-color: rgba(0, 100, 210, 0.16) !important;
                .mt-button__content {
                    color: #FF4300;
                }
                .mt-button__icon use {
                    fill: #b71600 !important;
                }
            }
        }
    }
    .mt-global-header__logo {
        display: none;
    }
}
.deeplink_logo {
    position: absolute;
    z-index: 100;
    top: 10px;
    margin-left: 91px;
    @media (max-width: 1023px) {
      margin-left: 16px;
      img {
          height: 26px;
      }
    }
  }
