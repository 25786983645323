.ErrorPage--grid-container__maincontent {
  margin-bottom: 0.25rem;
}

.ErrorPage-container {
  margin-top: 5rem;
  font-family: "Montserrat Regular", Arial;
  font-weight: 300;
}

.ErrorPage-submit {
  height: 48px;
  width: 290px;
  background-color: #1964C8;
  margin: 40px auto 0;
  padding-top: 7px;
  padding-right: 45px;
  padding-bottom: 7px;
}

.ErrorPage-submit:after {
  right: 1px;
}

.TopPageError-main-container .mt-alert > :last-child {
  margin-left: 0 !important;
}

.TopPageError-main-container .mt-alert.mt-card .mt-alert__icon {
  top: 1.1rem !important;
}

.TopPageError-main-container h2 {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 767px) {
  .ErrorPage-container {
    margin-top: 2rem;
  }
  .ErrorPage-error-banner {
    line-height: 40px;
  }
  .ErrorPage-submit {
    background-color: #1964C8;
    max-width: 375px;
  }
  .ErrorPage-submit:after {
    right: 1px;
  }
  .ErrorPage-oval {
    height: 100px;
    margin-bottom: 0px;
  }
  .ErrorPage-error-message {
    height: auto;
  }
}
@media screen and (min-width: 768px) {
  #errorpage-btn {
    font-size: 16px !important;
    width: 50% !important;
    padding: 20px !important;
    align-self: center;
  }
}
.ErrorChatSvg {
  width: 300px !important;
}

.Error-Page-Content {
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 22px;
}

.errorpage-backlink button, #voucherChatAblebtn {
  color: #db3a00;
}

.antifraud-link .able-icon use, .antifraud-link .able-icon path {
  fill: #FF4300 !important;
}

