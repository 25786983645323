@use "../../node_modules/@able/web/src/vars" as vars;
@use "../../node_modules/@able/web/src/foundations/grid" as grid;
@use "../../node_modules/@able/web/src/foundations/spacing" as spacing;
@use "../../node_modules/@able/web/src/mixins" as mixins;

$border-color: #FF4300;
$box-shadow: inset 0 0 0 0.0625rem $border-color, 0 0 0 0.0625rem $border-color, 0 0 0 0.1875rem rgb(0 100 210 / 16%);
$box-shadow-focus: inset 0 0 0 .0625rem $border-color,0 0 0 .0625rem $border-color,0 0 0 .25rem $border-color;
$background-color: rgba(236, 201, 184, 0.18);

.Payment {
    &--grid{
        &-container {
            @include grid.breakpointColumns(12);

            &__topcontent {
                @include grid.breakpointColumns(12, (offset:1, cols: 7));
                @include mixins.mq-alias("viewportXS") {
                    margin-top: 0px !important;
                }
            }
            &__maincontent {
                margin-bottom: spacing.spacing(.5);
                order: 1;

                @include mixins.mq-alias("viewportXS") {
                    order: 2;
                }
            }
            &__summary {
                margin-bottom: spacing.spacing(.5);
                padding-top: 22px;
                order: 2;

                @include mixins.mq-alias("viewportXS") {
                    order: 1;
                    margin-left: 0% !important;
                }
            }
            &__tabcontainer {
                @include grid.breakpointColumns(12);
                .tabs-container {
                    margin-bottom: spacing.spacing(7);
                    @include mixins.mq-alias("viewportXS") {
                        margin-bottom: spacing.spacing(5);
                    }
                }
            }
        }
        &_iFrameContainer iframe{
            width: 98% !important;
            border: none;
            height: 800px; 
            @include mixins.mq-alias("viewportXS") {
                width: 100% !important;
            }
        }
    }
    &-hide-braintree-iframe{
        display: none;
    }
    
    &-type-heading{
        margin-bottom: spacing.spacing(4);
    }
    
    &PannelLegacy{
        margin-top: 12px;
    }
    &TopPageErrorAlignment {
        margin-bottom: spacing.spacing(4);
    }
}

html {
    overflow-y: scroll;
}
#payment-root-content legend {
    border-bottom: 0;
}
