.SummaryBox {
  border-left: 4px solid #FF4300;
  box-sizing: border-box;
  max-width: 359px;
  border-right: 1px solid #D2D2D2;
  border-bottom: 1px solid #D2D2D2;
  border-top: 1px solid #D2D2D2;
  background-color: #FFFFFF;
}

.summary-card.success-card.mt-card {
  padding: 0 16px;
}

.summary-card.mt-card {
  -webkit-box-shadow: 0 0px 0px 0px #FFFFFF !important;
  box-shadow: 0 0px 0px 0px #FFFFFF !important;
  border: 0px solid #ffffff !important;
  max-width: 390px !important;
}

.summary-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.summary-heading {
  font-size: 16px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  margin-bottom: 8px !important;
}

.summary-content {
  color: #414141 !important;
  font-family: "Montserrat Regular", Arial !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  letter-spacing: 0.15px !important;
  line-height: 24px !important;
  padding-left: 10px;
  margin-bottom: 0px !important;
}

.summary-content.phone-number {
  margin-bottom: 24px !important;
}

.summary-content-country {
  height: 21px;
  width: 220px;
  color: #757575;
  font-family: "Montserrat Regular", Arial !important;
  font-size: 14px;
  letter-spacing: 0.18px;
  line-height: 21px;
  padding-left: 35px;
}

.summarypanel-promo-summary {
  word-break: break-word;
  padding-right: 24px;
  color: #757575;
  font-family: "Montserrat Regular", Arial !important;
  font-size: 14px;
  letter-spacing: 0.18px;
  line-height: 21px;
  padding-left: 35px;
  padding-top: 8px;
}

.summary-item svg, .summarypanel-details svg {
  height: 24px;
  width: 24px;
}

.summary-item path {
  fill: #FF4300;
}

.summary-totaltopay {
  border-top: 1px solid #D2D2D2 !important;
  margin-top: 24px !important;
}

.summary-span-totaltopay {
  display: flex;
  padding-top: 15px;
  justify-content: space-between;
}

.summary_beforediscount {
  text-align: right;
  margin-bottom: 0px;
}

.success-summary-box {
  border-left: 4px solid #FF4300;
  box-sizing: border-box;
  max-width: 306px;
  margin-top: 30px !important;
  margin-bottom: 45px;
}

.SuccessPage-payment-option-svg {
  margin-top: 32px;
}

.SuccessPage-MyTelstraAppSection {
  margin-top: 24px;
}

.summary-card-mt-card-content.success-card {
  padding-left: 0px !important;
  padding-top: 0px !important;
}

.SummaryPanel-title2 {
  width: 237px !important;
}

.promotions-data, .discounts-data {
  color: #FF4300 !important;
  font-family: "Montserrat SemiBold", Arial !important;
}

.summarypanel-details {
  float: left;
  padding-right: 10px;
}

.summarypanel-textstyle {
  display: flex;
  font-family: Montserrat Regular, Arial;
}

.summarypanel-details {
  margin-bottom: 0 !important;
}

.SummaryBox .summary-card {
  padding: 24px !important;
}

.summary-heading.panel-title2 {
  margin-bottom: 16px !important;
}

.success-wrapper .success-summary-box .summary-card-mt-card-content.success-card {
  padding: 0 16px !important;
}

