@use "../../node_modules/@able/web/src/vars" as vars;
@use "../../node_modules/@able/web/src/foundations/grid" as grid;
@use "../../node_modules/@able/web/src/foundations/spacing" as spacing;
@use "../../node_modules/@able/web/src/mixins" as mixins;

.global-message.mt-sheet--visible .mt-sheet__panel {
    left: inherit;
    top: 0;
    width: 31rem !important;
    height: inherit !important;
}
.global-message.mt-sheet .mt-sheet__close[type=button] {
    display: block !important;
}
.global-message-container .able-MessageGlobal--Attention {
    width: 100%;
    z-index: 1;
}
.globalheader {
    position: relative;
}
.global-message-container .able-icon use,.global-message-container .able-icon path {
    fill: #0064d2!important;
}
.global-message-container .able-MessageGlobal--Attention a:visited  {
    color: #1964c8;
}
@media only screen and (max-width: 767px){
    .global-message.mt-sheet--visible .mt-sheet__panel {
        bottom: 0 !important;
        height: 100% !important;
        width: 100% !important;
        top: 0 !important;
        left: inherit !important;
        margin-right: inherit !important;
        transform: translateY(0)!important;
        overflow: hidden;
        transition: transform .5s;
    }
    
}
