.Payment--grid-container {
  width: 100%;
}
.Payment--grid-container__topcontent {
  width: 100%;
}
@media screen and (min-width: 48rem) {
  .Payment--grid-container__topcontent {
    margin-left: 8.33%;
    width: 58.33%;
  }
}
@media screen and (max-width: 47.99609375rem) {
  .Payment--grid-container__topcontent {
    margin-top: 0px !important;
  }
}
.Payment--grid-container__maincontent {
  margin-bottom: 0.25rem;
  order: 1;
}
@media screen and (max-width: 47.99609375rem) {
  .Payment--grid-container__maincontent {
    order: 2;
  }
}
.Payment--grid-container__summary {
  margin-bottom: 0.25rem;
  padding-top: 22px;
  order: 2;
}
@media screen and (max-width: 47.99609375rem) {
  .Payment--grid-container__summary {
    order: 1;
    margin-left: 0% !important;
  }
}
.Payment--grid-container__tabcontainer {
  width: 100%;
}
.Payment--grid-container__tabcontainer .tabs-container {
  margin-bottom: 3.5rem;
}
@media screen and (max-width: 47.99609375rem) {
  .Payment--grid-container__tabcontainer .tabs-container {
    margin-bottom: 2.5rem;
  }
}
.Payment--grid_iFrameContainer iframe {
  width: 98% !important;
  border: none;
  height: 800px;
}
@media screen and (max-width: 47.99609375rem) {
  .Payment--grid_iFrameContainer iframe {
    width: 100% !important;
  }
}
.Payment-hide-braintree-iframe {
  display: none;
}
.Payment-type-heading {
  margin-bottom: 2rem;
}
.PaymentPannelLegacy {
  margin-top: 12px;
}
.PaymentTopPageErrorAlignment {
  margin-bottom: 2rem;
}

html {
  overflow-y: scroll;
}

#payment-root-content legend {
  border-bottom: 0;
}

