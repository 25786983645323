.info-banner {
  max-width: 100% !important;
}
.info-banner a {
  color: #FF4300 !important;
}
.info-banner svg:not(.able-icon):not(.able-picto) path, .info-banner svg:not(.able-icon):not(.able-picto) rect {
  fill: currentColor !important;
}

