body, html, #spectrum {
  min-width: 100% !important;
}

* {
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  background-color: #F8F8F8;
}

body {
  font-family: "Helvetica", Arial;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.main-content-wrapper .container.main-content {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.container {
  width: 100%;
  margin: 0 !important;
}

#root {
  width: 100%;
}

.global-container {
  max-width: 1012px !important;
  margin: 0 auto !important;
}

.container {
  width: 100%;
  margin: 0 !important;
}

.global-container {
  max-width: 1012px !important;
  margin: 0 auto !important;
}

button {
  font-family: "Helvetica", Arial;
}

button:active, #root button:disabled, button:active:after, #root button:disabled:after {
  background-color: #0090CC;
}

button:after {
  background-color: #1964c8 !important;
}

body {
  font-family: "Montserrat Regular", Arial;
  font-size: 14px;
}

#root h2 {
  font-family: "Montserrat Bold", Arial;
}

.search-telstra-btn, .search-telstra-btn:active, .search-telstra-btn:active:after {
  background-color: transparent !important;
}

.main-content-wrapper {
  flex: 1 0 auto !important;
}

.mt-global-header {
  height: 4rem !important;
}

@font-face {
  font-family: "Telstra Akkurat";
  font-weight: 300;
  font-style: normal;
  src: url("fonts/TelstraAkkurat-Light.eot");
  src: url("fonts/TelstraAkkurat-Light.eot?#iefix") format("embedded-opentype"), url("fonts/TelstraAkkurat-Light.woff2") format("woff2"), url("fonts/TelstraAkkurat-Light.woff") format("woff"), url("fonts/TelstraAkkurat-Light.ttf") format("truetype"), url("fonts/TelstraAkkurat-Light.svg#TelstraAkkurat-Light") format("svg");
}
@font-face {
  font-family: "Telstra Akkurat";
  font-weight: normal;
  font-style: normal;
  src: url("fonts/TelstraAkkurat-Regular.eot");
  src: url("fonts/TelstraAkkurat-Regular.eot?#iefix") format("embedded-opentype"), url("fonts/TelstraAkkurat-Regular.woff2") format("woff2"), url("fonts/TelstraAkkurat-Regular.woff") format("woff"), url("fonts/TelstraAkkurat-Regular.ttf") format("truetype"), url("fonts/TelstraAkkurat-Regular.svg#TelstraAkkurat-Regular") format("svg");
}
@font-face {
  font-family: "Telstra Gravur";
  font-weight: bold;
  font-style: normal;
  src: url("fonts/TelstraGravur-Bold.eot");
  src: url("fonts/TelstraGravur-Bold.eot?#iefix") format("embedded-opentype"), url("fonts/TelstraGravur-Bold.woff2") format("woff2"), url("fonts/TelstraGravur-Bold.woff") format("woff"), url("fonts/TelstraGravur-Bold.ttf") format("truetype"), url("fonts/TelstraGravur-Bold.svg#TelstraGravur-Bold") format("svg");
}
@font-face {
  font-family: "Montserrat Regular", Arial;
  font-weight: normal;
  font-style: normal;
  src: url("fonts/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat Bold", Arial;
  font-weight: normal;
  font-style: normal;
  src: url("fonts/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat ExtraBold", Arial;
  font-weight: normal;
  font-style: normal;
  src: url("fonts/Montserrat-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat SemiBold", Arial;
  font-weight: normal;
  font-style: normal;
  src: url("fonts/Montserrat-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat Medium", Arial;
  font-weight: normal;
  font-style: normal;
  src: url("fonts/Montserrat-Medium.ttf") format("truetype");
}
.visibility-hidden {
  display: none !important;
}

#spectrum {
  display: none !important;
}

h1:focus {
  outline: none !important;
  background-color: transparent !important;
}

.minimal-footer-homelink > .col-wrapper a {
  color: #333;
  font-weight: bold;
  font-size: 12px !important;
  font-size: 1.2rem;
}

.links-list a, .footer-links ul a {
  color: #333;
  font-size: 12px !important;
  font-size: 1.2rem;
  vertical-align: top;
}

.Page-title {
  font-family: "Montserrat Bold", Arial !important;
  color: #141414 !important;
  font-size: 40px !important;
  font-weight: bold !important;
  letter-spacing: -0.25px !important;
  line-height: 52px !important;
}

.HeadingD, #email-address {
  font-family: "Montserrat SemiBold", Arial !important;
}

.FinePrint {
  font-family: "Montserrat Regular", Arial !important;
  color: #282828;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 22px;
}

.HeadingA, .HeadingB, .total-amount, .SuccessPage-NextStep, #autorecharge-able-btn, .btn__label {
  font-family: "Montserrat Bold", Arial !important;
}

.mt-showhide__button, .Paypal-Label, .AutoRecharge-content, .Payment-paypalContinue-text {
  font-family: "Montserrat Regular", Arial !important;
}

#TopPageError-text p {
  font-family: "Montserrat Regular", Arial;
  font-size: 14px !important;
  letter-spacing: 0.18px;
  line-height: 20px;
}

@media (max-width: 767px) {
  .Page-title {
    font-weight: bold;
    letter-spacing: 0;
    line-height: 34px !important;
    font-size: 32px !important;
  }
}
/* Overriding Telstra UI to make the app look like boost */
.able-mt-button--high-emphasis {
  background-color: #FF4300 !important;
}
.able-mt-button--high-emphasis:focus:not(:hover) {
  box-shadow: 0 0 0 3px #FF4300 !important;
}

.able-mt-button--medium-emphasis {
  border: 1px solid black !important;
}
.able-mt-button--medium-emphasis:hover {
  background-color: rgba(255, 67, 0, 0.16) !important;
}
.able-mt-button--medium-emphasis::before {
  background-color: rgba(255, 67, 0, 0.16) !important;
}
.able-mt-button--medium-emphasis:focus:not(:hover) {
  box-shadow: 0 0 0 3px black !important;
  background-color: #FFE1D6 !important;
}

.able-ActionButton--high-emphasis {
  background-color: #FF4300 !important;
}
.able-ActionButton--high-emphasis:focus:not(:hover) {
  box-shadow: 0 0 0 3px #FF4300 !important;
}

.able-ActionButton--medium-emphasis {
  border: 1px solid black !important;
}
.able-ActionButton--medium-emphasis:hover {
  background-color: rgba(255, 67, 0, 0.16) !important;
}
.able-ActionButton--medium-emphasis::before {
  background-color: rgba(255, 67, 0, 0.16) !important;
}
.able-ActionButton--medium-emphasis:focus:not(:hover) {
  box-shadow: 0 0 0 3px black !important;
  background-color: #FFE1D6 !important;
}

.mt-showhide__button {
  color: #FF4300 !important;
}

.mt-showhide__button__icon:before,
.mt-showhide__button__icon:after {
  background-color: #FF4300 !important;
}

.able-mt-button--high-emphasis span,
.able-mt-button--medium-emphasis span {
  color: black;
}

.able-mt-button--low-emphasis-big,
.able-mt-button--low-emphasis {
  color: #DB3A00 !important;
}
.able-mt-button--low-emphasis-big span,
.able-mt-button--low-emphasis span {
  font-weight: 600;
}
.able-mt-button--low-emphasis-big svg path,
.able-mt-button--low-emphasis svg path {
  fill: #FF4300 !important;
}

.able-ActionButton--low-emphasis-big,
.able-ActionButton--low-emphasis {
  color: #DB3A00 !important;
}
.able-ActionButton--low-emphasis-big span,
.able-ActionButton--low-emphasis span {
  font-weight: 600;
}
.able-ActionButton--low-emphasis-big svg path,
.able-ActionButton--low-emphasis svg path {
  fill: #FF4300 !important;
}

.mt-button.mt-tabs__button--active {
  color: #FF4300 !important;
  border-bottom: 2px solid red !important;
}

.mt-global-header__nav__button.mt-button {
  display: block !important;
}

.mt-wrapper-ppr {
  display: block !important;
  width: 100% !important;
  padding: 0px;
}

.mt-navlist__link {
  min-width: 44px;
  min-height: 44px;
  padding: 7px 7px 7px 15px !important;
  color: #b71600 !important;
}

.mt-lozenge--new {
  background-color: #FF4300 !important;
}

.mt-breadcrumbs > :nth-last-child(2) .mt-breadcrumbs__icon {
  top: 24px !important;
}

.able-mt-text-field--tiny input {
  width: 16.66% !important;
}

.able-mt-text-field--short input {
  width: 33.33% !important;
}

.able-mt-text-field--default input {
  width: 50% !important;
}

.able-mt-text-field--long input, .able-TextField--Long input {
  width: 100% !important;
}

.hide-header {
  margin-bottom: 0px !important;
}

.alignIconAndText {
  display: flex;
  align-items: center;
}

.able-text-style--TextBodyShort {
  font-family: "Montserrat Regular", Arial !important;
  font-weight: normal !important;
}

.able-text-style--Label p {
  color: rgb(40, 40, 40);
  font-family: "Montserrat Regular", Arial !important;
  font-weight: bold;
}

.able-Checkbox input:checked ~ label::after {
  background-color: #FF4300 !important;
  box-shadow: inset 0 0 0 0.0625rem #ff4300, 0 0 0 0.0625rem #ff4300, 0 0 0 0.1875rem rgba(0, 100, 210, 0.08) !important;
}
.able-Checkbox input:checked:active ~ label::before {
  background-color: rgba(255, 67, 0, 0.08) !important;
}
.able-Checkbox input:focus ~ label::after {
  box-shadow: 0 0 0 0.1875rem #FF4300 !important;
}
.able-Checkbox--Comfortable input:checked ~ label::before, .able-Checkbox--Comfortable--Picto input:checked ~ label::before {
  box-shadow: inset 0 0 0 0.0625rem #ff4300, 0 0 0 0.0625rem #ff4300, 0 0 0 0.1875rem rgba(0, 100, 210, 0.08) !important;
}
.able-Checkbox--Comfortable input:checked ~ label::after, .able-Checkbox--Comfortable--Picto input:checked ~ label::after {
  background-color: #FF4300 !important;
  box-shadow: inset 0 0 0 0.0625rem #ff4300, 0 0 0 0.0625rem #ff4300, 0 0 0 0.1875rem rgba(0, 100, 210, 0.08) !important;
}
.able-Checkbox--Comfortable input:checked ~ label:active::before, .able-Checkbox--Comfortable--Picto input:checked ~ label:active::before {
  background-color: rgba(255, 67, 0, 0.08) !important;
}
.able-Checkbox--Comfortable input:checked:focus ~ label::before, .able-Checkbox--Comfortable--Picto input:checked:focus ~ label::before {
  box-shadow: inset 0 0 0 0.0625rem #FF4300, 0 0 0 0.0625rem #FF4300, 0 0 0 0.25rem #FF4300 !important;
}
.able-Checkbox--Comfortable input:hover ~ label::before, .able-Checkbox--Comfortable--Picto input:hover ~ label::before {
  box-shadow: inset 0 0 0 0.0625rem #FF4300, 0 0 0 0.0625rem #FF4300, 0 0 0 0.25rem #FF4300 !important;
  background-color: rgba(255, 67, 0, 0.08) !important;
}
.able-Checkbox--Comfortable input:focus ~ label::before, .able-Checkbox--Comfortable--Picto input:focus ~ label::before {
  box-shadow: inset 0 0 0 0.0625rem transparent, 0 0 0 0.0625rem #FF4300, 0 0 0 0.25rem #FF4300 !important;
  background-color: rgba(255, 67, 0, 0.08) !important;
}

.able-RadioGroup--Comfortable div input:checked ~ label::before, .able-RadioGroup--Comfortable--Icon div input:checked ~ label::before {
  box-shadow: inset 0 0 0 0.0625rem #FF4300, 0 0 0 0.0625rem #FF4300, 0 0 0 0.25rem #FF4300 !important;
  background-color: rgba(255, 67, 0, 0.08) !important;
}
.able-RadioGroup--Comfortable div input:checked ~ label::after, .able-RadioGroup--Comfortable--Icon div input:checked ~ label::after {
  background-color: #FF4300 !important;
}
.able-RadioGroup--Comfortable div input:checked ~ label:active::before, .able-RadioGroup--Comfortable--Icon div input:checked ~ label:active::before {
  background-color: rgba(255, 67, 0, 0.08) !important;
}
.able-RadioGroup--Comfortable div input:checked:hover ~ label::before, .able-RadioGroup--Comfortable--Icon div input:checked:hover ~ label::before {
  background-color: rgba(255, 67, 0, 0.08) !important;
  box-shadow: inset 0 0 0 0.0625rem #FF4300, 0 0 0 0.0625rem #FF4300, 0 0 0 0.25rem #FF4300 !important;
}
.able-RadioGroup--Comfortable div input:hover ~ label::before, .able-RadioGroup--Comfortable--Icon div input:hover ~ label::before {
  background-color: rgba(255, 67, 0, 0.08) !important;
  box-shadow: inset 0 0 0 0.0625rem #ff4300, 0 0 0 0.0625rem #ff4300, 0 0 0 0.1875rem rgba(0, 100, 210, 0.08) !important;
}
.able-RadioGroup--Comfortable div input:hover ~ label::after, .able-RadioGroup--Comfortable--Icon div input:hover ~ label::after {
  border-color: #FF4300 !important;
}
.able-RadioGroup--Comfortable div input:active ~ label::before, .able-RadioGroup--Comfortable--Icon div input:active ~ label::before {
  background-color: rgba(255, 67, 0, 0.08) !important;
}
.able-RadioGroup--Comfortable div input:focus ~ label::before, .able-RadioGroup--Comfortable--Icon div input:focus ~ label::before {
  background-color: rgba(255, 67, 0, 0.08) !important;
}

.able-RadioGroup--Comfortable fieldset legend {
  border-bottom-style: initial !important;
}

.able-MessageSection--Attention, .able-MessageSection--Attention--Interactive {
  border-left-color: #FF4300 !important;
}
.able-MessageSection--Information, .able-MessageSection--Information--Interactive {
  border-left-color: #FF4300 !important;
}
@media screen and (min-width: 48rem) {
  .able-MessageSection--Information, .able-MessageSection--Information--Interactive {
    max-width: 50% !important;
  }
}

.able-ActionButton--low-emphasis {
  color: #FF4300 !important;
}

.able-ActionButton--low-emphasis svg:not(.able-icon):not(.able-picto) path {
  fill: #FF4300 !important;
}

.able-ActionButton--low-emphasis-big svg:not(.able-icon):not(.able-picto) rect {
  fill: #FF4300 !important;
}

.offer-info-banner.able-MessageSection--Information, .offer-info-banner.able-MessageSection--Information--Interactive {
  border-left-color: #0064d2 !important;
}

.offer-info-banner .able-icon path, .offer-info-banner .able-icon use {
  fill: #0064d2 !important;
}

.Voucher--grid__container_form_container .able-Grid--padding--false {
  width: 100%;
}

.mt-illustration-icon {
  width: 3.5rem;
  height: 3.5rem;
}

