.mt-global-header {
  position: absolute;
  z-index: 99;
  background: #151515;
  border-bottom: 2px solid #FF4300;
}
.mt-global-header__content-push {
  height: 4rem;
}
@media (max-width: 1023px) {
  .mt-global-header__content-push {
    height: 3rem;
  }
}
.mt-global-header:before {
  display: none;
}
@media (max-width: 1023px) {
  .mt-global-header {
    height: 3rem !important;
  }
}
.mt-global-header__nav__button.mt-button {
  display: flex !important;
  width: 89px;
  margin-top: 1.1rem !important;
}
@media (max-width: 1023px) {
  .mt-global-header__nav__button.mt-button {
    margin-right: 0 !important;
    margin-top: 0.7rem !important;
  }
}
.mt-global-header__nav__button.mt-button .mt-button__icon {
  display: flex;
  transform: none !important;
  transition: none !important;
  right: 4rem;
  top: 0;
}
.mt-global-header__nav__button.mt-button .mt-button__icon .able-icon {
  margin-right: 0.1rem;
  margin-left: 0rem;
}
.mt-global-header__nav__button.mt-button .mt-button__content {
  color: #FF4300;
  padding: 0rem 0rem 0.1rem 1.5rem !important;
  line-height: 1.5rem !important;
}
.mt-global-header__nav__button.mt-button .mt-button__content:hover {
  color: #db3a00 !important;
  cursor: pointer;
}
.mt-global-header__nav__button.mt-button .mt-button__content:hover {
  color: #b71600;
}
.mt-global-header__nav__button.mt-button .mt-button__content:hover + .mt-button__icon use {
  fill: #b71600;
}
.mt-global-header__secondary-nav-without-children {
  margin-right: 5.5%;
}
@media (max-width: 1023px) {
  .mt-global-header__secondary-nav-without-children {
    margin-right: 12px;
  }
}
.mt-global-header__secondary-nav-without-children button {
  height: 2.8rem !important;
  padding: 0 !important;
}
.mt-global-header__secondary-nav-without-children button:hover {
  background-color: transparent !important;
  cursor: auto;
}
.mt-global-header__secondary-nav-without-children button:focus {
  border: 1px solid #0064D2;
  box-shadow: 0px 0px 0px 1px #0099F8;
  background-color: rgba(0, 100, 210, 0.16) !important;
}
.mt-global-header__secondary-nav-without-children button:focus .mt-button__content {
  color: #FF4300;
}
.mt-global-header__secondary-nav-without-children button:focus .mt-button__icon use {
  fill: #b71600 !important;
}
.mt-global-header .mt-global-header__logo {
  display: none;
}

.deeplink_logo {
  position: absolute;
  z-index: 100;
  top: 10px;
  margin-left: 91px;
}
@media (max-width: 1023px) {
  .deeplink_logo {
    margin-left: 16px;
  }
  .deeplink_logo img {
    height: 26px;
  }
}

