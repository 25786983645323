.EnterNumber--grid-container__outagecontent {
  margin-bottom: 0.25rem;
}
@media screen and (max-width: 47.99609375rem) {
  .EnterNumber--grid-container__outagecontent {
    order: 2 !important;
  }
}
.EnterNumber--grid-container__maincontent {
  margin-bottom: 0.25rem;
}
@media screen and (max-width: 47.99609375rem) {
  .EnterNumber--grid-container__maincontent {
    order: 2;
  }
}
.EnterNumber--grid-container__image {
  margin-bottom: 0.25rem;
  margin-top: 2.5rem;
}
.EnterNumber--grid-container__image svg {
  width: 358px;
}
@media screen and (min-width: 64rem) and (max-width: 104.99609375rem) {
  .EnterNumber--grid-container__image {
    order: 1;
  }
}
@media screen and (min-width: 48rem) and (max-width: 63.99609375rem) {
  .EnterNumber--grid-container__image {
    order: 1;
  }
  .EnterNumber--grid-container__image svg {
    width: 300px;
  }
}
@media screen and (max-width: 47.99609375rem) {
  .EnterNumber--grid-container__image {
    order: 1;
    padding-top: 0;
    margin-top: 2rem;
  }
  .EnterNumber--grid-container__image svg {
    width: 300px;
  }
}

.able-icon path, .able-icon use {
  fill: #FF4300 !important;
}
.able-icon path[href$="#Error"], .able-icon use[href$="#Error"] {
  fill: #d0021b !important;
}
.able-icon path[href$="#Success"], .able-icon use[href$="#Success"] {
  fill: #008a00 !important;
}

.container.main-content {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.enter-number-title {
  width: 100%;
}

.do-business {
  padding: 14px 46px 14px 20px !important;
  margin-right: 0;
}

.do-business:after {
  right: 0;
}

.EnterNumber-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.EnterNumber-your-number {
  color: #333333;
  font-weight: bold;
  line-height: 17px;
  align-self: flex-start;
  width: 100% !important;
  margin-bottom: 0px;
}

.EnterNumber-input {
  margin-top: 3px;
  width: 100% !important;
  flex-grow: 0;
}

.EnterNumber-input:focus {
  border: 1px solid #00AAF3;
}

.EnterNumber-example {
  height: 20px;
  color: #333333;
  font-style: italic;
  font-size: 12px;
  line-height: 20px;
  margin-top: 2px;
  align-self: flex-start;
}

.columnErrorIcon {
  margin-right: 10px;
  float: left;
}

.errorMessage {
  color: #BB2914;
  line-height: 18px;
  width: 80%;
  vertical-align: middle;
  display: table-cell;
  padding-left: 0;
}

.error-icon {
  background: url("./inline-error-cross.svg") no-repeat 50% 50%;
  content: "";
  width: 18px;
  height: 18px;
}

.div-icon-enter-number {
  height: 20px;
  width: 600px;
  margin-bottom: 5px;
  margin-top: 3px;
}

.div-icon-voucher {
  width: 600px;
  height: 39px;
  display: table;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Voucher-container-section {
  padding-bottom: 20px;
}

.Voucher-section-no {
  margin-right: 16px;
}

.EnterNumber-container-voucher {
  width: 100%;
}

.enter-number-wrapper {
  width: 100%;
}

.showDivSection {
  display: block;
}

.hideDivSection {
  display: none;
}

.div-icon-enter-number-voucher {
  width: auto;
  height: 29px;
}

.Mobile-number-text {
  font-family: "Montserrat Regular", Arial !important;
  line-height: 28px;
}

.enternumber-able-btn {
  width: 100% !important;
  padding: 20px !important;
  font-size: 16px !important;
}

.enternumber-input-field {
  display: flex;
  position: relative;
}

.EnterNumber-voucher-question-error {
  color: #C91800 !important;
}

.EnterNumber-mobile-surface {
  padding: 1.5rem;
  margin-bottom: 0px !important;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .Mobile-number-text {
    margin-top: 12px;
    margin-bottom: -8px;
  }
}
@media only screen and (max-width: 767px) {
  .main-chooseAmount-container {
    margin-top: 0px !important;
  }
  .div-icon-enter-number {
    width: auto;
    margin-bottom: 29px !important;
  }
  .div-icon-enter-number-voucher {
    width: auto;
    height: 29px !important;
  }
  .EnterNumber-submit {
    width: 100% !important;
    flex-grow: 1;
  }
  .Voucher-container-section {
    width: 100%;
    float: left;
  }
  .mobileImageDiv {
    display: block;
    margin-bottom: 32px;
    height: auto;
  }
  .mobileImageDiv svg {
    width: 252px;
    margin: auto;
    display: block;
  }
  .enter-number-wrapper {
    margin: 0px;
  }
  .row {
    display: inline-block;
    margin-left: 0px;
  }
}
.mt-showhide__button__icon__left {
  margin-left: 21px !important;
}

.mt-showhide__button {
  font-size: 16px !important;
  line-height: 24px !important;
  padding-left: 33px !important;
  background-color: transparent !important;
  outline: 0 !important;
  margin-top: 10px !important;
}

.mt-showhide__button:focus:not(:hover) {
  z-index: 2 !important;
  outline: 1px dotted #FF4300 !important;
  background-color: #FFF3EB !important;
  transition: background-color 0.5s !important;
}

.mt-showhide {
  margin-left: 5px;
  border: none !important;
}

.mt-showhide__button:hover {
  background-color: transparent !important;
}

.mt-showhide__content__inner {
  color: #414141 !important;
  font-size: 14px !important;
  letter-spacing: 0.18px !important;
  line-height: 21px !important;
  margin-top: 6px !important;
}

.mt-showhide__button__icon {
  left: 0px !important;
  right: auto !important;
  margin-left: 23px !important;
  top: 3px !important;
}

.mt-showhide__button__icon:before {
  border: 1px solid #ff4300 !important;
}

.mt-showhide__button__icon:after {
  border: 1px solid #ff4300 !important;
}

@media only screen and (min-width: 320px) and (max-width: 568px) {
  .mt-showhide__content__inner {
    color: #414141 !important;
    font-size: 14px !important;
    letter-spacing: 0.18px !important;
    line-height: 21px !important;
    margin-top: 15px !important;
  }
  .mt-showhide__button__icon {
    top: 4px !important;
  }
}
.VoucherHeading:not(:focus-visible) {
  outline: none !important;
  background-color: transparent !important;
}

.EnterNumber--grid-container__image .mt-illustration,
.EnterNumber--grid-container__image .mt-illustration .mt-illustration__sm,
.EnterNumber--grid-container__image .mt-illustration .mt-illustration__sm svg {
  width: 100% !important;
  max-width: 358px !important;
}

.EnterNumber-ShowHide-Button-Custom button {
  color: #FF4300 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  padding-left: 0px !important;
  background-color: transparent !important;
  outline: 0 !important;
  margin-top: 10px !important;
  box-shadow: none !important;
}

.EnterNumber-ShowHide-Button-Custom.able-Accordion--compact button:hover {
  box-shadow: none;
  background-color: transparent;
}

.EnterNumber-ShowHide-Button-Custom.able-Accordion--compact > div.accordion--expanded > div {
  box-shadow: none;
}

.EnterNumber-ShowHide-Button-Custom.able-Accordion--compact > div > div > div {
  padding-left: 0px;
  padding-right: 0px;
}

