@use "../../../node_modules/@able/web/src/mixins" as mixins;

.able-Sequence--Default--End a:focus:not(:hover), .able-Sequence--Default--End button:focus:not(:hover) {
    box-shadow: 0 0 0 0.1875rem #FF4300 !important;
    background-color: rgba(255,67,0,.06) !important;
}

.able-Sequence--Default--End a, .able-Sequence--Default--End button {
    color: #db3a00 !important;
}
