.AppDownload {
  width: 100%;
  margin-top: 2.5rem;
}
.AppDownload__container {
  width: 100%;
  margin-bottom: 0.25rem;
  box-sizing: border-box;
  border: 1px solid #D2D2D2;
  border-radius: 5px;
  padding-left: 0;
  padding-right: 0;
  margin-right: 10%;
}
.AppDownload__logocontainer {
  width: 100%;
  margin-bottom: 1.5rem;
  background: url("./boost-downloadapp.jpg") no-repeat;
  background-size: 100%;
  height: 0;
  padding-bottom: 53.9215686275%;
  display: block;
}
.AppDownload__titlecontainer {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
}
.AppDownload__titlecontainer--title {
  margin-bottom: 0.25rem;
  float: left;
  width: 50%;
  flex: 1;
  padding-left: 8%;
}
.AppDownload__titlecontainer--image {
  background: url("./mytelstra-icon.svg") no-repeat 100% 50%;
  margin-bottom: 0.25rem;
  flex: 1;
  float: right;
  margin-right: 8%;
}
@media screen and (min-width: 48rem) and (max-width: 63.99609375rem) {
  .AppDownload__titlecontainer--image {
    min-height: 64px;
  }
}
@media screen and (max-width: 47.99609375rem) {
  .AppDownload__titlecontainer--image {
    min-height: 64px;
  }
}
.AppDownload__contentcontainer {
  margin-left: 8.33%;
  width: 83.33%;
  margin-bottom: 2rem;
}
.AppDownload__contentcontainer .summary-item {
  margin-top: 16px;
}
.AppDownload__contentcontainer .summary-label-content {
  color: #414141 !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  letter-spacing: 0.15px !important;
  line-height: 24px !important;
  margin-bottom: 5px !important;
}
.AppDownload__contentcontainer .summary-item-content {
  color: #414141 !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  letter-spacing: 0.15px !important;
  line-height: 24px !important;
  margin-bottom: 5px !important;
  padding-left: 16px;
}
.AppDownload__contentcontainer .summary-item path {
  fill: #FF4300;
}
.AppDownload__button {
  width: 100% !important;
  border-color: black;
  color: black !important;
}
.AppDownload__tick {
  float: left;
  padding-right: 16px;
}
.AppDownload__tick path {
  fill: #FF4300;
}

