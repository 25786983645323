.TopPageError-container, .MessageSection-TopPageError-container {
    margin-top: 32px;
}
.TopPageError-main-container {
    width: 100% !important;
}

.TopPageError-main-container .mt-alert{
    width: 100% !important;
}

.Error-heading {
    font-family: "Montserrat Bold",Arial,sans-serif;
    font-size: 16px;
}

.TopPageError-main-container .mt-alert p {
    font-size: 14px !important;
}

.error-message {
    margin-left: 40px !important; 
}

.TopPageError-body {    
    font-size: 14px !important;
    line-height: 20px !important;
}

#topPageError-activationLink {
    text-decoration: underline!important;
}

#topPageError-activationLink a:focus {
    outline: none;
}

.TopPageError-main-container .mt-alert__icon svg{
    width: 24px !important;
    height: 24px !important;
    margin-left: 2px;
}

.TopPageError-main-container .mt-alert>:last-child{
    margin-left: 0px !important;
}

.TopPageError-main-container .mt-alert.mt-alert--error:not(.mt-alert--inline):after{
    height: 2px !important;
}
.TopPageError-main-container .mt-card{
    padding:16px 32px 18px 2.5rem !important;
}

.TopPageError-main-container .mt-alert.mt-card .mt-alert__icon{
    top: 1.1rem !important;
}

.TopPageError-main-container h2 {
    margin-bottom: 0 !important;
}

@media (max-width: 767px) {
    .TopPageError-container {
        max-width: 100%;
        margin-left: 0;
    }
}
@media (min-width: 768px) {
    .TopPageError-container {
        margin-right: 23px;
    }
    #accessdenied-btn{
        font-size: 16px !important;
        width: 50% !important;
        padding: 20px !important;
        align-self: center;
       
    }
}

@media only screen and (min-width: 420px){
    .mt-alert{
        width: 398px !important;
    }
}