@use "../../node_modules/@able/web/src/vars" as vars;
@use "../../node_modules/@able/web/src/foundations/grid" as grid;
@use "../../node_modules/@able/web/src/foundations/spacing" as spacing;
@use "../../node_modules/@able/web/src/mixins" as mixins;
@use "../../node_modules/@able/web/src/foundations/colour" as colour;

.Voucher--grid {
    &__container{
        @include grid.breakpointColumns(12);
        @include mixins.mq-alias("viewportXS") {
            padding-bottom: 80px;
        }
        &_heading{
            @include grid.breakpointColumns(12,(offset:1, cols:8));
            margin-top: spacing.spacing(5);
            @include mixins.mq-alias("viewportXS") {
                margin-top: spacing.spacing(4);
            }
        }

        &_error_container{
            @include grid.breakpointColumns(12,(offset:1, cols:4.5));
            margin-bottom: 21px;
            @include mixins.mq-alias("viewportSM") {
                @include grid.breakpointColumns(12,(offset:1, cols:6));
            }
            .mt-alert {
                width: 100% !important;
            }  
        }

        &_form_container{
            @include grid.breakpointColumns(12,(offset:1, cols:4.5));
            @include mixins.mq-alias("viewportSM") {
                @include grid.breakpointColumns(12,(offset:1, cols:6));
            }
        }
        
        &_input_container{
            @include grid.breakpointColumns(12);
            .voucher-text-field-container{
                margin-bottom: 32px;
            }
        }

        &_image{
            @include grid.breakpointColumns(12,(offset:2, cols:3));
            margin-top: 20px;

            svg {
                width: 150px;
            }

            @include mixins.mq-alias("viewportXS") {
                display: none;
            }
            @include mixins.mq-alias("viewportSM") {
                @include grid.breakpointColumns(12,(offset:2, cols:3));
            }

            &_mobile{
                @include grid.breakpointColumns(12);
                display: none;
                @include mixins.mq-alias("viewportXS") {
                    display: block;
                    svg {
                        width: 60px;
                    }
                }
            }
        }
    }
}
.Voucher{
    &_example {
        margin-bottom: 30px;
    }
    &_submit_btn{
        width: 100% !important;
    }
    &_error_message {
        width: 90%;
        color: #BB2914;;
        line-height: 21px;
        padding-left: 9px;
        vertical-align: text-bottom;
        display: inline-block !important;
    }
}
@media screen and (min-width: 48rem) {
    .Voucher--grid__container_image > .mt-illustration {
        width: 19.4375rem;
    }
}
@media screen and (max-width: 48rem) {
    .Voucher_product_illustration {
        display: none;
    }
}
